import * as React from 'react';
import { useCallback, useState } from 'react';
import { AppBar, Box, IconButton, Menu, MenuItem, Paper, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
import { useAuth } from '../hooks/useAuth';
import { useLoading } from '../hooks/useLoading';


interface Props {
    title?: string | JSX.Element | JSX.Element[];
    state?: 'ready' | 'loading' | 'warning'
    actions?: string | JSX.Element | JSX.Element[];
    backDepth?: number;
    children: React.ReactNode;
}

const AppBarPage = ({ title, actions, backDepth, children }: Props): JSX.Element => {
    const auth = useAuth();
    const navigate = useNavigate();
    const loc = useLocation();
    const loading = useLoading();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const onMenuClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const onMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onLogoutClick = useCallback(async () => {
        if (auth) {
            await auth.logout();
            navigate('/', { replace: true });
            navigate(0);
        }
    }, [auth, navigate]);

    return (
        <Paper sx={{ padding: 0, minHeight: '100vh' }} elevation={6} square>
            <AppBar position='static'>
                <Toolbar>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        {loc.pathname.split('/').length >= 3 ? (
                            <Link
                                to={
                                    loc.state?.prevPath ||
                                    loc.pathname.split('/').slice(0, -(backDepth || 2)).join('/')
                                }
                                style={{ color: 'inherit' }}
                            >
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                    onClick={() => {
                                        loading.showLoading();
                                    }}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            </Link>
                        ) : (
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={onMenuClick}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}

                        { typeof title === 'string' ? (
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    position: 'absolute',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    textAlign: 'center',
                                }}
                            >
                                {title}
                            </Typography>
                        ) : (
                            title
                        )}

                        {actions && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    flexGrow: 0,
                                    textAlign: 'right',
                                }}
                            >
                                {actions}
                            </Box>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            {children}
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClick={onMenuClose}
                onClose={onMenuClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            left: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <MenuItem onClick={onLogoutClick}>
                    <Logout /> Logout
                </MenuItem>
            </Menu>
        </Paper>
    );
}

export default AppBarPage;