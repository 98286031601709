class SessionStorage<T> {
    private key: string;
    constructor(key: string) {
      this.key = key;
    }

    set(value: T): void {
      try {
        const serializedValue = JSON.stringify(value);
        window.sessionStorage.setItem(this.key, serializedValue);
      } catch (error) {
        console.error(`Error saving to sessionStorage for key "${this.key}":`, error);
      }
    }

    get(): T | null {
      try {
        const serializedValue = window.sessionStorage.getItem(this.key);
        if (serializedValue === null) return null;
        return JSON.parse(serializedValue) as T;
      } catch (error) {
        console.error(`Error loading from sessionStorage for key "${this.key}":`, error);
        return null;
      }
    }

    remove(): void {
      try {
        window.sessionStorage.removeItem(this.key);
      } catch (error) {
        console.error(`Error removing from sessionStorage for key "${this.key}":`, error);
      }
    }
  }

  export default SessionStorage;