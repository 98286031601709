export type CustomValue = string | number | boolean | Date;
export type JoinFormat = "rows" | "columns" | "no";
export type FieldState = "required" | "enabled" | "disabled";
export type FieldType = "string" | "number" | "date" | "boolean" | "enum" | "autocomplete";

export interface Entity {
  readonly _id: string;
  readonly created?: string;
  readonly updated?: string;
  readonly customFields?: CustomFields;
}
export interface CustomFields {
  [k: string]: CustomValue;
}
export interface Client extends Entity {
  readonly name: string;
  readonly sheetId: string;
  readonly practiceId: number;
  readonly measures?: string[];
  readonly checks?: string[];
  readonly isMultiPlan: boolean;
  readonly layout: Layout;
  readonly lastSync?: string;
  readonly importSheetId?: string;
  readonly importFunctionCode?: string;
  readonly exportSheetId?: string;
  readonly exportFunctionCode?: string;
  readonly repNames?: string[];
  readonly userAccess?: UserAccessInfo[];
}
export interface Layout {
  readonly options: Options;
  readonly patientFields: ParentField[];
  readonly visitFields: ParentField[];
  readonly measureFields: Field[];
  readonly checkFields: Field[];
}
export interface Options {
  readonly isMultiPlan: boolean;
  readonly measuresSheet?: string;
  readonly checksSheet?: string;
  readonly patientsSheet?: string;
  readonly programsSheet?: string;
  readonly visitsSheet?: string;
  readonly clientMeasuresSheet?: string;
  readonly planMeasuresSheet?: string;
  readonly planMeasuresFormat: JoinFormat;
  readonly patientMeasuresSheet?: string;
  readonly patientMeasuresFormat: JoinFormat;
  readonly patientChecksSheet?: string;
  readonly patientChecksFormat: JoinFormat;
  readonly clientChecksSheet?: string;
  readonly planChecksSheet?: string;
  readonly planChecksFormat: JoinFormat;
  readonly numCallAttempts?: number;
  readonly numSMSAttempts?: number;
  readonly numEmailAttempts?: number;
  readonly numMailAttempts?: number;
  readonly enableSyncInPatients?: boolean;
}
export interface ParentField extends Field {
  readonly forChild: boolean;
}
export interface Field {
  readonly id: string;
  readonly label: string;
  readonly state: FieldState;
  readonly defaultLabel?: string;
  readonly clearLabel?: string;
  readonly section?: string;
  readonly field?: string;
  readonly type?: FieldType;
  readonly items?: string[];
  readonly minItems?: 0;
}
export interface UserAccessInfo {
  readonly name: string;
  readonly id: number;
  readonly assignable: boolean;
  readonly options: string[];
}
export interface Plan extends Entity {
  readonly name: string;
  readonly measures?: string[];
  readonly checks?: string[];
  readonly clientId: string;
}
export interface DocumentInfo {
  readonly lastEditedDate?: string;
  readonly lastEditedBy?: string;
  readonly lastEditedById?: number;
  readonly lastEditReason?: string;
}
export interface AssignmentInfo {
  readonly assignedDate?: string;
  readonly assignedTo?: string;
  readonly assignedToId?: number;
  readonly assignedBy?: string;
  readonly assignedById?: number;
}
export interface OutreachItem {
  readonly date?: string;
  readonly rep?: string;
  readonly source?: string;
  readonly outcome?: string;
}
export interface Outreach {
  readonly callAttempts?: number;
  readonly calls?: OutreachItem[];
  readonly smsAttempts?: number;
  readonly sms?: OutreachItem[];
  readonly emailAttempts?: number;
  readonly email?: OutreachItem[];
  readonly mailAttempts?: number;
  readonly mailcopy?: OutreachItem[];
}
export interface Patient extends Entity {
  readonly refId: string;
  readonly medicaidId?: string;
  readonly medicareId?: string;
  readonly status?: PatientStatus;
  readonly info?: DocumentInfo;
  readonly assignment?: AssignmentInfo;
  readonly lineOfBusiness?: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly gender?: Gender;
  readonly dob?: string;
  readonly address?: string;
  readonly address2?: string;
  readonly city?: string;
  readonly county?: string;
  readonly state?: string;
  readonly zip?: string;
  readonly phone?: string;
  readonly phone2?: string;
  readonly email?: string;
  readonly guardianName?: string;
  readonly guardianPhone?: string;
  readonly guardianEmail?: string;
  readonly subProgram?: string;
  readonly pcpName?: string;
  readonly pcpPhone?: string;
  readonly pcpNPI?: string;
  readonly practiceName?: string;
  readonly groupName?: string;
  readonly caseManager?: string;
  readonly caseManagerPhone?: string;
  readonly language?: string;
  readonly numMeasures?: number;
  readonly numMeasuresClosed?: number;
  readonly numMeasuresCompliant?: number;
  readonly risk?: number;
  readonly risk2?: number;
  readonly riskCategory?: string;
  readonly scheduled?: string;
  readonly measures?: string[];
  readonly checks?: string[];
  readonly clientId: string;
  readonly planId?: string;
}
export interface Visit extends Entity {
  readonly refId: string;
  readonly clientId: string;
  readonly patientId: string;
  readonly appointmentId?: number;
  readonly status: VisitStatus;
  readonly info?: DocumentInfo;
  readonly scheduled1?: string;
  readonly completed1?: string;
  readonly scheduledReason1?: string;
  readonly scheduled2?: string;
  readonly completed2?: string;
  readonly scheduledReason2?: string;
  readonly scheduled3?: string;
  readonly scheduledReason3?: string;
  readonly scheduled4?: string;
  readonly scheduledReason4?: string;
  readonly comments?: string;
  readonly sentToPCP?: boolean;
  readonly clientNotes?: string;
  readonly clientDataEntry?: string;
  readonly refusalReason?: string;
  readonly outcomeReason?: string;
  readonly language?: string;
  readonly numCalls?: number;
  readonly numSMS?: number;
  readonly numMails?: number;
  readonly numEmails?: number;
  readonly numContacts?: number;
  readonly numReschedules?: number;
  readonly phoneDate?: string;
  readonly phoneOutcome?: string;
  readonly emailDate?: string;
  readonly emailOutcome?: string;
  readonly utrDate?: string;
  readonly scheduledDate?: string;
  readonly appointmentOutcome?: string;
  readonly notes?: string;
  readonly memberInfoUpdate?: string;
  readonly lastContactDate?: string;
}
export interface Trackable extends Entity {
  readonly name: string;
  readonly clientId: string;
  readonly patientId: string;
  readonly visitId: string;
  readonly type?: "measure" | "check";
}
export interface Measure extends Trackable {
  readonly closed: boolean;
  readonly complianceStatus: MeasureComplianceStatus;
}
export interface Check extends Trackable {
  readonly done: boolean;
  readonly positive: boolean;
}
export interface CheckType {
  readonly name: string;
  readonly frequency?: number;
  readonly duration?: number;
}
export interface Program extends Entity {
  readonly name: string;
  readonly clientId: string;
  readonly checks: CheckType[];
}
export interface Job {
  readonly _id: string;
  readonly clientId: string;
  readonly created?: string;
  readonly updated?: string;
  readonly type: string;
  readonly name: string;
  readonly status: JobStatus;
  readonly progress?: number;
  readonly log?: string[];
}
export interface ClusterPoint {
  readonly id: string;
  readonly address: string;
  readonly latitude?: number;
  readonly longitude?: number;
  readonly priority?: number;
  readonly patient?: Patient;
  readonly visit?: Visit;
}
export interface ClusterJobFilter {
  readonly field: string;
  readonly values: string[];
}
export interface ClusterJobParams {
  readonly minSize: number;
  readonly maxSize: number;
  readonly units: string;
  readonly minPoints: number;
  readonly maxPoints: number;
  readonly filter?: ClusterJobFilter[];
}
export interface Cluster {
  readonly points: ClusterPoint[];
  readonly borderPoints: number[];
  readonly radius: {
    value?: number;
    units?: string;
  };
}
export interface ClusterJobResult {
  readonly clusters: Cluster[];
}
export interface ClusterJob extends Job {
  readonly params: ClusterJobParams;
  readonly result: ClusterJobResult;
}
export interface LatLng {
  readonly latitude: number;
  readonly longitude: number;
}
export interface Agent {
  readonly id: string;
  readonly name: string;
  readonly gender: string;
  readonly age: number;
  readonly language: string;
  readonly office: string;
  readonly address: string;
  readonly coords?: LatLng;
}
export interface Provider {
  readonly id: string;
  readonly name: string;
  readonly specialty?: string;
}
export interface PatientVisit extends Patient {
  readonly visit: Visit;
}
export interface ScheduleJobParams {
  readonly visitDuration: number;
  readonly providerLoad: number;
  readonly maxIterations: number;
  readonly maxTimeSeconds: number;
  readonly femaleAgentPreferred: boolean;
  readonly patientsFilter: {
    city: string[];
    county: string[];
    plan: string[];
    risk: string[];
    status: string[];
  };
  readonly agents: Agent[];
  readonly providers: Provider[];
}
export interface ScheduleItem {
  readonly visit: Visit;
  readonly patient: Patient;
  readonly agentId: string;
  readonly providerId: string;
  readonly address: string;
  readonly coords: LatLng;
  readonly day: number;
  readonly startTime: number;
  readonly duration: number;
}
export interface ScheduleJobResult {
  readonly schedule: ScheduleItem[];
  readonly agents: Agent[];
  readonly providers: Provider[];
}
export interface ScheduleJob extends Job {
  readonly params: ScheduleJobParams;
  readonly result?: ScheduleJobResult;
}
export interface SchedulableSlot {
  readonly patientId: string;
  readonly time: string;
  readonly duration: number;
  readonly agentId: string;
  readonly agentName?: string;
  readonly providerId: string;
  readonly providerName?: string;
  readonly info: DocumentInfo;
}
export interface TrackableMeasure {
  readonly id?: string;
  readonly name: string;
  readonly value?: string;
  readonly status?: string;
  readonly type: TrackableMeasureTypes;
  readonly created?: string;
  readonly info: DocumentInfo;
}
export interface PatientTrackables extends Entity {
  readonly name: string;
  readonly clientId: string;
  readonly patientId: string;
  readonly visitId?: string;
  readonly trackable?: TrackableMeasure[];
  readonly info: DocumentInfo;
}

export const enum Gender {
  MALE = "Male",
  FEMALE = "Female",
  UNKNOWN = "Prefer not to say"
}
export const enum PatientStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  EXCLUDED = "Excluded",
  DONOTCONTACT = "Do Not Contact"
}
export const enum VisitStatus {
  NEW = "New",
  IN_PROGRESS = "In Progress",
  INACTIVE = "Inactive",
  SCHEDULED = "Scheduled",
  COMPLETED = "Completed",
  REFUSED = "Refused",
  UTR = "UTR (unable to reach)"
}
export const enum MeasureComplianceStatus {
  IGNORED = "Ignored",
  NON_COMPLIANT = "Non-Compliant",
  COMPLIANT = "Compliant"
}
export const enum JobStatus {
  NEW = "new",
  IN_PROGRESS = "in_progress",
  SUCCESS = "success",
  FAIL = "fail",
  TERMINATING = "terminating"
}
export const enum TrackableMeasureTypes {
  CHRONIC = "chronic",
  SUSPECT = "suspect",
  DIAGNOSIS = "diagnosis",
  MEASURE = "measure"
}
