import * as React from 'react';
import { useCallback, useState } from 'react';
import { Client, Patient, Plan, Gender, PatientStatus, DocumentInfo, AssignmentInfo, ParentField, 
    PatientTrackables, TrackableMeasure, TrackableMeasureTypes, 
    VisitStatus} from '../models/core';
import { API } from '../utils/Api';
import { Box, FormControl, InputLabel, MenuItem, Select, 
    TextField, Typography, Grid, Divider, Tabs, Tab, 
    IconButton,
    Tooltip} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { GridFormItem } from './GridForm';
import { UnknownLanguage, Genders, Languages, LoBs, PatientStatuses } from '../utils/enums';
import CustomFields from './CustomFields';
import { DateTime } from 'luxon';
import { authDocumentInfo } from '../utils/ResourceUtils'
import EntityEditor, { EntityEditorProps } from './EntityEditor';
import { analytics } from '../utils/analytics/zipAnalytics';
import { EnumField, AutocompleteField } from './fields';
import { useAuth } from '../hooks/useAuth';
import useSnackbar from '../hooks/useSnackbar';
import AlertSnack from './ErrorSnack'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import ConditionInfoEditor, { PatientConditionPanelData, PatientConditionEditorState } from './ConditionInfoEditor';
import PatientMeasureEditor, { PatientMeasureEditorData, PatientMeasureEditorState } from './PatientMeasureEditor';
import { useChangeTracker } from '../hooks/useChangeTracker';

export enum PatientSection {
    Demographics = 'Demographics',
    Contacts = 'Contacts',
    Insurance = 'Insurance',
    Client = 'Client',
    Provider = 'Provider',
    Custom = 'Custom'
}

export enum PatientEditorMode {
    Dialog = 'Dialog',
    Grid = 'Grid',
    Element = 'Element'
}

interface PatientEditorProps extends Omit<EntityEditorProps,'title'|'doSave'> {
    patient?: Patient | null;
    client: Client;
    plans: Plan[];
    sections?:PatientSection[];
    editorMode?:PatientEditorMode
}

function parseStrArray(key: string|undefined, strArr:string[]) {
    const lck = key?.toLocaleLowerCase();
    return strArr.find(item => item.toLocaleLowerCase() === lck) || key; 
}

const PatientEditor = ({ client, patient, plans, sections, editorMode, readOnly, ...props }: PatientEditorProps): JSX.Element => {
    const [refId, setRefId] = useState(patient?.refId || '');
    const [medicaidId, setMedicaidId] = useState(patient?.medicaidId || '');
    const [medicareId, setMedicareId] = useState(patient?.medicareId || '');
    const [status, setStatus] = useState(patient?.status || PatientStatus.ACTIVE + '');
    const [language, setLanguage] = useState(parseStrArray(patient?.language, Languages) ||  UnknownLanguage);
    const [planId, setPlanId] = useState(patient?.planId || '');
    const [lineOfBusiness, setLineOfBusiness] = useState(patient?.lineOfBusiness || '');
    const [firstName, setFirstName] = useState(patient?.firstName || '');
    const [lastName, setLastName] = useState(patient?.lastName || '');
    const [gender, setGender] = useState(patient?.gender || '');
    const [dob, setDob] = useState<DateTime | null>(patient?.dob && DateTime.fromISO(patient.dob) || null);
    const [address, setAddress] = useState(patient?.address || '');
    const [city, setCity] = useState(patient?.city || '');
    const [state, setState] = useState(patient?.state || '');
    const [zip, setZip] = useState(patient?.zip || '');
    const [phone, setPhone] = useState(patient?.phone || '');
    const [phone2, setPhone2] = useState(patient?.phone2 || '');
    const [subProgram, setSubProgram] = useState(patient?.subProgram || '');
    const [pcpName, setPcpName] = useState(patient?.pcpName || '');
    const [pcpPhone, setPcpPhone] = useState(patient?.pcpPhone || '');
    const [caseManager, setCaseManager] = useState(patient?.caseManager || '');
    const [caseManagerPhone, setCaseManagerPhone] = useState(patient?.caseManagerPhone || '');
    const [risk, setRisk] = useState(patient?.risk || 0);
    const [customFields, setCustomFields] = useState(patient?.customFields || {});
    const [errors, setErrors] = useState<{ [k in keyof Patient]?: boolean }>({});
    const [address2, setAddress2] = useState(patient?.address2 || '');
    const [county, setCounty] = useState(patient?.county || '');
    const [email, setPatientEmail] = useState(patient?.email || '');
    const [riskCategory, setRiskCategory] = useState(patient?.riskCategory || '');
    const [pcpNPI, setPCPNPI] = useState(patient?.pcpNPI || '');
    const [practiceName, setPracticeName] = useState(patient?.practiceName || '');
    const [groupName, setGroupName] = useState(patient?.groupName || '');
    const [guardianName, setGuardianName] = useState(patient?.guardianName || '');
    const [guardianPhone, setGuardianPhone] = useState(patient?.guardianPhone || '');
    const [guardianEmail, setGuardianEmail] = useState(patient?.guardianEmail || '');
    const [assignment, setAssignment] = useState<AssignmentInfo | null> (patient?.assignment || null);

    const [displayTabValue, setDisplayTabValue] = useState(0);
    
    const [patientConditionState, setPatientConditionState] = useState<PatientConditionEditorState>();
    const [patientMeasureState, setPatientMeasureState] = useState<PatientMeasureEditorState>();
    const [patientTrackables, setPatientTrackables] = useState<PatientTrackables>();
    
    const { showError: snackError, ...snackProps } = useSnackbar();

    const auth = useAuth();
    const { testStringChanges, getChangesString } = useChangeTracker();

    const layoutCustomFields = React.useMemo(() =>
        client?.layout.patientFields.filter(f => !f.field) || []
    , [client]);

    const parseAge = useCallback((date:DateTime|null) => {
        if(date === null) {
            return '';
        }
        const today = DateTime.now();
        let ageNow = today.year - date.year;
        const month = today.month - date.month;
        if (month < 0 || (month === 0 && today < date)) {
            ageNow--;
        }
        return ageNow;
    },[]);

    const checkRenderSection = useCallback((section:PatientSection, array:PatientSection[], def = true) => {
        return array?.length == 0 ? def : array?.findIndex((s) => section == s) >= 0;
    },[]);

    React.useEffect(() => {
        setRefId(patient?.refId || '');
        setMedicaidId(patient?.medicaidId || '');
        setMedicareId(patient?.medicareId || '');
        setStatus(patient?.status || PatientStatus.ACTIVE + '');
        setLanguage(parseStrArray(patient?.language, Languages) || UnknownLanguage);
        setPlanId(patient?.planId || '');
        setLineOfBusiness(patient?.lineOfBusiness || '');
        setFirstName(patient?.firstName || '');
        setLastName(patient?.lastName || '');
        setGender(patient?.gender || '');
        setDob(patient?.dob && DateTime.fromISO(patient.dob) || null);
        setAddress(patient?.address || '');
        setCity(patient?.city || '');
        setState(patient?.state || '');
        setZip(patient?.zip || '');
        setPhone(patient?.phone || '');
        setPhone2(patient?.phone2 || '');
        setSubProgram(patient?.subProgram || '');
        setPcpName(patient?.pcpName || '');
        setPcpPhone(patient?.pcpPhone || '');
        setCaseManager(patient?.caseManager || '');
        setCaseManagerPhone(patient?.caseManagerPhone || '');
        setRisk(patient?.risk || 0);
        setCustomFields(patient?.customFields || {});

        setAddress2(patient?.address2 || '');
        setCounty(patient?.county || '');
        setPatientEmail(patient?.email || '');
        setRiskCategory(patient?.riskCategory || '');
        setPCPNPI(patient?.pcpNPI || '');
        setPracticeName(patient?.practiceName || '');
        setGroupName(patient?.groupName || '');
        setGuardianName(patient?.guardianName || '');
        setGuardianPhone(patient?.guardianPhone || '');
        setGuardianEmail(patient?.guardianEmail || '');
        setAssignment(patient?.assignment || null);

        async function fetchPatientData() {
            if(patient && patient?.clientId && patient?._id) {
                const trackableList = await API.getPatientTrackables(patient?.clientId, patient?._id);
                setPatientTrackables((trackableList?.items && trackableList?.items.length > 0) ? trackableList?.items[0] : undefined);

                const conditionsList:Record<string, PatientConditionPanelData[]> = {};
                const measuresList:PatientMeasureEditorData[] = [];
                trackableList?.items?.forEach((it) => {
                        it?.trackable?.forEach((item) => {
                            if(item.type == 'chronic' || item.type == 'suspect' || item.type == 'diagnosis') {
                                if(!conditionsList[item.type]) {
                                    conditionsList[item.type] = [];
                                }
                                conditionsList[item.type].push({
                                    _id:item.id || (Date.now().toString() + 'a' + conditionsList[item.type].length).toString(),
                                    visitId:it.visitId,
                                    name:item.name || '',
                                    value:item.value || '',
                                    info:item.info
                                })
                            } else if(item.type == 'measure') {
                                measuresList.push({
                                    _id:item.id || (Date.now().toString() + 'a' + conditionsList[item.type].length).toString(),
                                    visitId:it.visitId,
                                    name:item.name || '',
                                    value:item.value || '',
                                    info:item.info
                                })
                            }
                        })
                    }
                );

                setPatientConditionState({
                    updated:false,
                    chronic:conditionsList['chronic'] || [], 
                    suspect:conditionsList['suspect'] || [], 
                    diagnosis:conditionsList['diagnosis'] || [], 
                });
                setPatientMeasureState({
                    updated:false,
                    measures:measuresList
                });
            }
        }
        fetchPatientData();

    }, [patient])

    const disabledFields = React.useMemo(() => (
        client?.layout.patientFields.filter(f => f.state == 'disabled').flatMap(f => f.field ? [f.field] : []) || []
    ), [client]);

    const layoutCustomSectionFields = React.useMemo(():{ [key: string]: ParentField[] } => {
        const sections: { [key: string]: ParentField[] } = {};
        client?.layout.patientFields.forEach(f => {
            if(f.section) {
                if (!sections[f.section])
                    sections[f.section] = [];
                sections[f.section].push(f);
            }
        });
        return sections;
    }, [client]);

    const onDobChange = useCallback((value: string | null) => {
        setDob(value && DateTime.fromISO(value) || null);
    }, []);

    const onAssignedRep = useCallback((name: string, value: string | null) => {
        setAssignment({ assignedDate: DateTime.now().toString(), assignedTo: value||'None', assignedBy: auth?.user?.name })
    }, [auth?.user?.name, setAssignment]);

    const onCustomFieldChange = useCallback((value: { [k: string]: string | number | Date | boolean | undefined }) => {
        setCustomFields(Object.fromEntries(Object.entries(value).filter(([, v]) => v).map(([k, v]) => [k, v || ''])));
    }, [setCustomFields]);

    const lastEditFooter = React.useMemo(() => {
        const udt = patient?.info?.lastEditedDate ? patient?.info?.lastEditedDate : patient?.updated;
        const editor = patient?.info?.lastEditedBy || 'unknown';
        const ntm = udt ? `Updated: ${(udt.includes('.') ? udt.split('.')[0] : udt)} by ${editor}` : '';
        return { text:ntm, tooltip:patient?.info?.lastEditReason };
    }, [patient?.updated, patient?.info]);

    const submitHedisTrackables = React.useCallback(async (patientId:string, clientId:string) => {
        if(!patientConditionState?.updated && !patientMeasureState?.updated) {
            return;
        }

        const trackableElements:TrackableMeasure[] = [
            ...(patientConditionState?.chronic?.map((m) => {
                return { id:m._id, name:m.name, value:m.value, type:TrackableMeasureTypes.CHRONIC, info:m.info }
            }) || []),
            ...(patientConditionState?.suspect?.map((m) => {
                return { id:m._id, name:m.name, value:m.value, type:TrackableMeasureTypes.SUSPECT, info:m.info }
            }) || []),
            ...(patientConditionState?.diagnosis?.map((m) => {
                return { id:m._id, name:m.name, value:m.value, type:TrackableMeasureTypes.DIAGNOSIS, info:m.info }
            }) || []),
            ...(patientMeasureState?.measures?.map((m) => {
                return { id:m._id, name:m.name, value:m.value, type:TrackableMeasureTypes.MEASURE, info:m.info }
            }) || [])
        ];

        if(patientId) {
            const trackInfo = authDocumentInfo(auth?.user,
                `chronic: ${patientConditionState?.chronic?.length || 0} `
                + `suspect: ${patientConditionState?.suspect?.length || 0} `
                + `diagnosis: ${patientConditionState?.diagnosis?.length || 0} `
                + `measures: ${patientMeasureState?.measures?.length || 0}`);
            if(patientTrackables?._id) {
                await API.updatePatientTrackables({ ...patientTrackables, ...{trackable:trackableElements, info:trackInfo}});
            } else {
                await API.createPatientTrackables({ name:patientId, patientId:patientId,
                    clientId:clientId, trackable:trackableElements, info:trackInfo});
            }
        }
    },[auth?.user, patientConditionState, patientMeasureState, patientTrackables]);

    const onSubmitClick = useCallback(async () => {

        let invalidPatient = !refId;
        if (!patient && !invalidPatient) {
            invalidPatient = await (await API.getPatients(client._id, [['refId',refId]]))?.items?.length != 0;
            if(invalidPatient) {
                snackError(new Error(`${refId} already exists for practice ${client.practiceId}`));
            }
        }

        const errors = {
            firstName: !firstName,
            refId: !refId || invalidPatient,
            dob:!(dob?.toISODate() || undefined),
            lastName: !lastName,
            address: !address,
            city: !city,
            state: !state,
            zip: !zip
        };
        setErrors(errors);
        if (Object.values(errors).reduce((acc, val) => acc || val)) {
            return false;
        }
        const info:DocumentInfo = authDocumentInfo(auth?.user,getChangesString());
        const assign = assignment || { assignedBy:'', assignedDate:'', assignedTo:'None'};
        if(assign?.assignedTo != patient?.assignment?.assignedTo) {
            analytics.track('assign', {  type:'single', patient:patient?._id || '',
                to:assign?.assignedTo || '',  by: auth?.user?.name || 'NA'
            }, true);
        }

        const newPatient = {
            refId: refId || '',
            status: status as PatientStatus,
            medicaidId,
            medicareId,
            lineOfBusiness,
            firstName,
            lastName,
            gender: gender as Gender,
            dob: dob?.toISODate() || undefined,
            address,
            address2,
            city,
            county,
            state,
            zip,
            phone,
            phone2,
            email,
            guardianName,
            guardianPhone,
            guardianEmail,
            subProgram,
            pcpName,
            pcpPhone,
            pcpNPI,
            practiceName,
            groupName,
            caseManager,
            caseManagerPhone,
            language,
            risk,
            riskCategory,
            customFields,
            info,
            assignment: assign,
        };
        analytics.track(patient ? 'patient_update': 'patient_create', {
            clientId: client._id,
            patientId: refId
        }, true)

        const newVisit = {
            status: VisitStatus.NEW,
            language: language || undefined,
            scheduled1: undefined, scheduled2: undefined,
            scheduled3: undefined, scheduled4: undefined,
            scheduledReason1: undefined, scheduledReason2: undefined,
            scheduledReason3: undefined, scheduledReason4: undefined,
            comments: undefined, clientNotes: undefined, clientDataEntry: undefined,
            appointmentId: undefined, customFields: undefined,
            numCalls: 0, numEmails: 0, numSMS: 0, numMails: 0,
            numContacts: 0, numReschedules : 0, refusalReason: undefined,
            phoneDate: undefined, emailDate: undefined, utrDate: undefined,
            scheduledDate: undefined, phoneOutcome: undefined, emailOutcome: undefined,
            appointmentOutcome: undefined, notes: undefined,
            memberInfoUpdate: undefined, info: info, lastContactDate: 'unknown'
        };
        
        let patId = patient?._id;
        if (patient) {
            await API.updatePatient({ ...patient, ...newPatient });
        } else {
            const { acknowledged, insertedId } = await API.createPatient({ clientId: client._id, ...newPatient });
            if(!acknowledged) {
                snackError(new Error('Failed to create patient, try again in a few minutes.'));
                analytics.error('patient_create_error', { clientId: client._id, }, true);
                return false;
            }
            patId = insertedId;
            
            analytics.track('visit_create', { clientId: client._id, patientId: refId, }, true);
            await API.createVisit({ clientId:client._id, patientId:patId, refId:'Visit1', ...newVisit })
        }

        if(patId) {
            await submitHedisTrackables(patId, client._id);
        }
         
        return true;
    }, [firstName, refId, medicaidId, medicareId, lineOfBusiness, lastName, gender, dob, address, address2, 
        city, county, state, zip, phone, phone2, email, subProgram, guardianName, guardianPhone, guardianEmail, 
        pcpName, pcpPhone, pcpNPI, practiceName, groupName, caseManager, caseManagerPhone, language, risk, 
        riskCategory, customFields, status, auth, patient, assignment, client._id, client.practiceId, 
        snackError, getChangesString, submitHedisTrackables]);


    const generateCustomSectionItems = React.useCallback((section:PatientSection) => {
        const lsections:ParentField[] = layoutCustomSectionFields[section];
        if(!lsections) {
            return <></>;
        }
        const customFieldKeys = Object.keys(customFields);
        const sectionFields = lsections.filter((s) => s.field && customFieldKeys.includes(s.field) && s.state != 'disabled').map((key, i) => 
            (<GridFormItem key={`lcs-${section}-${i}`} xs={4}>
                <TextField label={key.label} name={key.field} value={key.field ? customFields[key.field] as string : ''} variant='standard'/>                             
            </GridFormItem>
        )) || [];
        return (
            sectionFields.length > 0 ? <Grid container spacing={2}>
                <GridFormItem xs={12}><Box sx={{ height: '0.5em' }} /></GridFormItem>
                <GridFormItem item xs={12} padding='4px 0px 0px 0px'><Divider variant="middle" /></GridFormItem>
                { sectionFields }
                <GridFormItem item xs={12} padding='4px 0px 0px 0px'><Divider variant="middle" /></GridFormItem>
            </Grid> : <></>);
    }, [customFields, layoutCustomSectionFields]);

    const updatedStringValue = React.useCallback((oldValue:string, event:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>,
        setter:(value:React.SetStateAction<string>) => void) => {
            if(oldValue !== event.target.value) {
                testStringChanges(event.target.name, oldValue, event.target.value);
                setter(event.target.value);
            }
    }, [testStringChanges]);

    const updatedPairedStringValue = React.useCallback((name:string, oldValue:string, newValue:string|null,
        setter:(value:React.SetStateAction<string>) => void) => {
            if(oldValue !== newValue) {
                testStringChanges(name, oldValue, newValue||'');
                setter(newValue||'');
            }
    }, [testStringChanges]);

    const copyAddressString = React.useCallback(() => {
        navigator.clipboard.writeText(`${address} ${address2}, ${city}, ${state}. ${zip}`);
    },[address, address2, city, state, zip])

    //  Demographics
    const demographicsComponents = React.useMemo<JSX.Element>(() => {
        return (
            checkRenderSection(PatientSection.Demographics, sections || []) ? (
            <GridFormItem xs={12}>
            <Grid container spacing={2}>
                <GridFormItem xs={6}>
                    <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Demographics</Typography>
                </GridFormItem>
                <GridFormItem xs={3}>
                    <EnumField name='assignTo' label='Assigned To' value={assignment?.assignedTo || 'None'} 
                        onChange={onAssignedRep} options={client?.repNames || []} required readOnly={readOnly} />
                </GridFormItem>
                <GridFormItem xs={1}/>
                <GridFormItem xs={2}>
                    <EnumField name='status' label='Status' value={status} 
                        onChange={(n,v) => updatedPairedStringValue(n, status, v, setStatus)}
                        options={PatientStatuses} required readOnly={readOnly} />
                </GridFormItem>
                <Grid item xs={12}/>
                <GridFormItem xs={6}>
                    <TextField name='firstName' label='First Name' value={firstName} error={errors.firstName}
                        onChange={ (ev) => updatedStringValue(firstName, ev, setFirstName)}
                        inputProps={{ readOnly: readOnly, }} variant='standard' required />
                </GridFormItem>
                <GridFormItem xs={6}>
                    <TextField name='lastName' label='Last Name' value={lastName} error={errors.lastName}
                        onChange={ (ev) => updatedStringValue(lastName, ev, setLastName)}
                        inputProps={{ readOnly: readOnly, }} variant='standard' required />
                </GridFormItem>
                <GridFormItem xs={3}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker label='Date of Birth' inputFormat='yyyy-MM-dd' value={dob} renderInput={
                                (params) => <TextField name='dob' required {...params} error={errors.dob} variant='standard' helperText='Format: YYYY-MM-DD' />
                            }
                            onChange={onDobChange} readOnly={readOnly} />
                    </LocalizationProvider>
                </GridFormItem>
                <GridFormItem xs={1}><Box sx={{ height: '1em' }} /></GridFormItem>
                <GridFormItem xs={1}>
                    <TextField name='age' label='Age' value={parseAge(dob)} variant='standard' inputProps={{ readOnly: true, }} />
                </GridFormItem>
                <GridFormItem xs={1}><Box sx={{ height: '1em' }} /></GridFormItem>
                <GridFormItem xs={2}>
                    <EnumField name='gender' label='Sex Assigned Birth' value={gender}
                        onChange={(n,v) => updatedPairedStringValue(n, gender, v, setGender)}
                        options={Genders} required readOnly={readOnly} />
                </GridFormItem>
                <GridFormItem xs={1} ><Box sx={{ height: '1em' }} /></GridFormItem>
                <GridFormItem xs={3}>
                    <EnumField name='language' label='Language' value={language||UnknownLanguage}
                        onChange={(n,v) => updatedPairedStringValue(n, language, v, setLanguage)}
                        options={Languages} required readOnly={readOnly} />
                </GridFormItem>
            </Grid>
            {   generateCustomSectionItems(PatientSection.Demographics) }
            <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>
            </GridFormItem>) : <></>
        )
    },[firstName, lastName, dob, gender, language, onDobChange, status,
        updatedStringValue, parseAge, readOnly, sections, errors, checkRenderSection, assignment, 
        client?.repNames, onAssignedRep, generateCustomSectionItems, updatedPairedStringValue]);

    //  Contacts
    const contactComponents = React.useMemo<JSX.Element>(() => {
            return (
                checkRenderSection(PatientSection.Contacts, sections || []) ? (
                <GridFormItem xs={12}>
                <Grid container spacing={2}>
                    <GridFormItem xs={11}>
                        <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Contacts & Address</Typography>
                    </GridFormItem>
                    <GridFormItem xs={1}>
                        <Tooltip title='Copy Address'>
                        <IconButton onClick={copyAddressString}><ContentCopyIcon/></IconButton>
                        </Tooltip>
                    </GridFormItem>

                    <GridFormItem xs={4}>
                        <TextField name='phone' label='Phone' value={phone} error={errors.phone}
                            onChange={ (ev) => updatedStringValue(phone, ev, setPhone)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='phone2' label='Alt Phone' value={phone2} error={errors.phone2} disabled={disabledFields.includes('phone2')} 
                                onChange={ (ev) => updatedStringValue(phone2, ev, setPhone2)}
                                inputProps={{ readOnly: readOnly, }} variant='standard' />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='email' label='Email' value={email} disabled={disabledFields.includes('email')}
                            onChange={ (ev) => updatedStringValue(email, ev, setPatientEmail)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={12}><Box sx={{ height: '1em' }} /></GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='guardianName' label='Guardian Name' disabled={disabledFields.includes('guardianName')} value={guardianName} 
                            onChange={ (ev) => updatedStringValue(guardianName, ev, setGuardianName)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='guardianPhone' label='Guardian Phone' disabled={disabledFields.includes('guardianPhone')} 
                            inputProps={{ readOnly: readOnly, }} value={guardianPhone} error={errors.phone}
                            onChange={ (ev) => updatedStringValue(guardianPhone, ev, setGuardianPhone)}
                            variant='standard' fullWidth/>
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='guardianEmail' label='Guardian Email' disabled={disabledFields.includes('guardianEmail')} 
                            inputProps={{ readOnly: readOnly, }} value={guardianEmail}
                            onChange={ (ev) => updatedStringValue(guardianEmail, ev, setGuardianEmail)}
                            variant='standard' fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={12}><Box sx={{ height: '1em' }} /></GridFormItem>
                    <GridFormItem xs={12}>
                        <TextField name='address' label='Address' value={address} error={errors.address}
                            onChange={ (ev) => updatedStringValue(address, ev, setAddress)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' required fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='address2' label='Address 2' value={address2} disabled={disabledFields.includes('address2')} 
                            onChange={ (ev) => updatedStringValue(address2, ev, setAddress2)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth/>
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='county' label='County' value={county} disabled={disabledFields.includes('county')} 
                            onChange={ (ev) => updatedStringValue(county, ev, setCounty)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='city' label='City' value={city} error={errors.city}
                            onChange={ (ev) => updatedStringValue(city, ev, setCity)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' required />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='state' label='State' value={state} error={errors.state}
                            onChange={ (ev) => updatedStringValue(state, ev, setState)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' required />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='zip' label='ZIP' value={zip} error={errors.zip}
                            onChange={ (ev) => updatedStringValue(zip, ev, setZip)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' required />
                    </GridFormItem>
                </Grid>
                {   generateCustomSectionItems(PatientSection.Contacts) }
                <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>
                </GridFormItem>) : <></>
            )
        },[checkRenderSection, readOnly, sections, disabledFields, 
            zip, state, city, county, address2, address, updatedStringValue,
            errors.state, errors.zip, errors.address, errors.city, generateCustomSectionItems,
            email, guardianEmail, guardianName, guardianPhone, phone, phone2, errors.phone, errors.phone2]);

        //  Insurance
        const insuranceComponents = React.useMemo<JSX.Element>(() => {
            return (
                checkRenderSection(PatientSection.Insurance, sections || []) ? (
                <GridFormItem xs={12}>
                <Grid container spacing={2}>
                    <GridFormItem xs={12}>
                        <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Insurance</Typography>
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='medicaidId' label='Medicaid ID' value={medicaidId} error={errors.medicaidId}
                            onChange={ (ev) => updatedStringValue(medicaidId, ev, setMedicaidId)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth/>
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='medicareId' label='Medicare ID' value={medicareId} error={errors.medicareId}
                            onChange={ (ev) => updatedStringValue(medicareId, ev, setMedicareId)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth/>
                    </GridFormItem>
                    <GridFormItem xs={12}>
                    <FormControl
                        variant='standard'
                        required
                        error={errors.planId}
                        fullWidth
                        sx={{ visibility: plans.length > 0 ? 'visible' : 'hidden' }}>
                        <InputLabel>Plan</InputLabel>
                        <Select name='planId' value={planId}
                            onChange={(ev, _n) => updatedPairedStringValue(ev.target.name, planId, ev.target.value.toString(), setPlanId)}
                            readOnly={readOnly}>
                            {plans.map((plan, i) => (
                                <MenuItem key={`item-${i}`} value={plan._id}>{plan.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='subProgram' label='Sub-Program' value={subProgram} error={errors.subProgram}
                            disabled={disabledFields.includes('subProgram')} 
                            onChange={ (ev) => updatedStringValue(subProgram, ev, setSubProgram)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth/>
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <AutocompleteField name='line-of-business' label='Line of Business' 
                            value={lineOfBusiness} error={errors.lineOfBusiness}
                            onChange={(n,v) => updatedPairedStringValue(n, lineOfBusiness, v, setLineOfBusiness)}
                            options={LoBs} required readOnly={readOnly}
                            disabled={disabledFields.includes('lineOfBusiness')}/>
                    </GridFormItem>
                </Grid>
                {   generateCustomSectionItems(PatientSection.Insurance) }
                <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>
                </GridFormItem>) : <></>
            )
        },[checkRenderSection, readOnly, sections, lineOfBusiness, disabledFields,
            subProgram, planId, medicareId, medicaidId, errors.lineOfBusiness, errors.medicaidId, errors.medicareId, errors.subProgram,
            plans, errors.planId, generateCustomSectionItems, updatedPairedStringValue, updatedStringValue]);

        //  Client
        const clientComponents = React.useMemo<JSX.Element>(() => {
            return (
                checkRenderSection(PatientSection.Client, sections || []) ? (
                <GridFormItem xs={12}>
                <Grid container spacing={2}>
                    <GridFormItem xs={12}>
                        <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Client</Typography>
                    </GridFormItem>
                    <GridFormItem xs={12}>
                        <TextField name='refId' label='Client ID' value={refId} error={errors.refId}
                            onChange={ (ev) => updatedStringValue(refId, ev, setRefId)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' required fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='risk' label='Risk' value={risk} error={errors.risk} disabled={disabledFields.includes('risk')} 
                            onChange={(e) => setRisk(Number.parseFloat(e.target.value))}
                            inputProps={{ readOnly: readOnly, }} variant='standard' />
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='riskCategory' label='Risk Category' value={riskCategory} error={errors.riskCategory} disabled={disabledFields.includes('riskCategory')} 
                            onChange={ (ev) => updatedStringValue(riskCategory, ev, setRiskCategory)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth />
                    </GridFormItem>
                </Grid>
                {   generateCustomSectionItems(PatientSection.Client) }
                <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>
                </GridFormItem>) : <></>
            )
        },[checkRenderSection, refId, riskCategory, readOnly, sections, updatedStringValue,
            risk, errors.refId, errors.riskCategory, disabledFields, errors.risk, generateCustomSectionItems]);

        const providerComponents = React.useMemo<JSX.Element>(() => {
            return (
                checkRenderSection(PatientSection.Provider, sections || []) ? (
                <GridFormItem xs={12}>
                <Grid container spacing={2}>
                    <GridFormItem xs={12}>
                        <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Provider / Case Manager</Typography>
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='pcpName' label='Provider Name' value={pcpName} error={errors.pcpName} disabled={disabledFields.includes('pcpName')} 
                            onChange={ (ev) => updatedStringValue(pcpName, ev, setPcpName)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='pcpPhone' label='Provider Phone' value={pcpPhone} error={errors.pcpPhone} disabled={disabledFields.includes('pcpPhone')} 
                            onChange={ (ev) => updatedStringValue(pcpPhone, ev, setPcpPhone)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' />
                    </GridFormItem>
                    <GridFormItem xs={4}>
                        <TextField name='pcpNPI' label='Provider NPI' value={pcpNPI} error={errors.pcpNPI} disabled={disabledFields.includes('pcpNPI')} 
                            onChange={ (ev) => updatedStringValue(pcpNPI, ev, setPCPNPI)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' />
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='practiceName' label='Practice Name' value={practiceName} error={errors.practiceName} disabled={disabledFields.includes('practiceName')} 
                            onChange={ (ev) => updatedStringValue(practiceName, ev, setPracticeName)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='groupName' label='Group Name' value={groupName} error={errors.groupName} disabled={disabledFields.includes('groupName')} 
                            onChange={ (ev) => updatedStringValue(groupName, ev, setGroupName)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth/>
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='caseManager' label='Case Manager' value={caseManager} error={errors.caseManager} disabled={disabledFields.includes('caseManager')} 
                            onChange={ (ev) => updatedStringValue(caseManager, ev, setCaseManager)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth />
                    </GridFormItem>
                    <GridFormItem xs={6}>
                        <TextField name='caseManagerPhone' label='Case Manager Phone' value={caseManagerPhone} error={errors.caseManagerPhone} disabled={disabledFields.includes('caseManagerPhone')} 
                            onChange={ (ev) => updatedStringValue(caseManagerPhone, ev, setCaseManagerPhone)}
                            inputProps={{ readOnly: readOnly, }} variant='standard' fullWidth/>
                    </GridFormItem>
                </Grid>
                {   generateCustomSectionItems(PatientSection.Provider) }
                <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>
                </GridFormItem>) : <></>
            )
        },[checkRenderSection, pcpName, pcpPhone, practiceName, groupName, caseManager, caseManagerPhone, updatedStringValue,
            readOnly, sections, disabledFields, pcpNPI, errors.caseManager, errors.caseManagerPhone, errors.pcpNPI, errors.practiceName, errors.pcpName,
            errors.groupName, errors.pcpPhone, generateCustomSectionItems]);

        const customComponents = React.useMemo<JSX.Element>(() => {
            return (
                layoutCustomFields.length > 0 && !readOnly && checkRenderSection(PatientSection.Custom, sections || []) ? (
                <GridFormItem xs={12}>
                <Grid container spacing={2}>
                    <GridFormItem xs={12}>
                        <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Custom Fields</Typography>
                    </GridFormItem>
                    <GridFormItem xs={12}>
                        <CustomFields value={customFields || {}} fields={layoutCustomFields} onChange={onCustomFieldChange} />
                    </GridFormItem>
                </Grid>
                </GridFormItem>) : <></>
            )
        },[onCustomFieldChange, checkRenderSection, layoutCustomFields, customFields, readOnly, sections]);

        const customReadOnlyComps = React.useMemo<JSX.Element>(() => {

            const fieldKeys = (client.layout.patientFields.filter((l) => l && (l.field || l.section))).map((f) => f?.field);
            const keysWithoutFields = Object.keys(customFields).filter((k) => !fieldKeys.includes(k)).map((key, i) => 
                (<GridFormItem key={`lc-${i}`} xs={4}>
                     <TextField label={key} name={key} value={customFields[key] as string} variant='standard'/>                             
                </GridFormItem>
            )) || []

            return (
                keysWithoutFields?.length > 0 && !readOnly && checkRenderSection(PatientSection.Custom, sections || []) ? (
                <GridFormItem xs={12}>
                <Grid container spacing={2}>
                    <GridFormItem xs={12}>
                        <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Read Only</Typography>
                    </GridFormItem>
                    {
                        keysWithoutFields
                    }
                </Grid>
                </GridFormItem>) : <></>
            )
        },[checkRenderSection, client.layout.patientFields, customFields, readOnly, sections]);

    const onConditionStateUpdate = React.useCallback((state:PatientConditionEditorState) => {
        setPatientConditionState(state);
    },[setPatientConditionState]);

    const onMeasureStateUpdate = React.useCallback((state:PatientMeasureEditorState) => {
        setPatientMeasureState(state);
    },[setPatientMeasureState]);

    if(editorMode == undefined || PatientEditorMode.Dialog == editorMode) {
        return (<EntityEditor {...props} readOnly={readOnly} title={(readOnly ? 'View' : (patient ? 'Edit' : 'Create')) + ' Patient'} 
                footer={lastEditFooter} doSave={onSubmitClick} submitText={patient?'Save':'Create'} backdropClose={false}
                dialogPaperProps={{sx:{maxWidth:'800px', height:'100%', minHeight:'90%'}}}>
                
                <GridFormItem xs={12}>
                    <Tabs value={displayTabValue} 
                        onChange={(event: React.SyntheticEvent, newValue: number) => { setDisplayTabValue(newValue)} }
                        TabIndicatorProps={{ style: { backgroundColor: 'blue' } }}>
                        <Tab label="Patient" />
                        <Tab label="Conditions" />
                        <Tab label="Measures" />
                    </Tabs>
                </GridFormItem>
            {
                displayTabValue == 0 ?
                    (<GridFormItem xs={12}>
                        { demographicsComponents }{ contactComponents }{ insuranceComponents }{ clientComponents }{ providerComponents }{ customComponents }{ customReadOnlyComps }
                    </GridFormItem>)
                    : displayTabValue == 1 ?
                    (<GridFormItem xs={12}>
                        <ConditionInfoEditor state={patientConditionState} onStateUpdate={onConditionStateUpdate}/>
                    </GridFormItem>)
                    : (<GridFormItem xs={12}>
                        <PatientMeasureEditor state={patientMeasureState} onStateUpdate={onMeasureStateUpdate}/>
                    </GridFormItem>
                 )
            }
            <AlertSnack {...snackProps}/>
        </EntityEditor >);
    } else if(PatientEditorMode.Grid == editorMode) {
        return <Grid container spacing={2}>
            { demographicsComponents }{ contactComponents }{ insuranceComponents }{ clientComponents }{ providerComponents }{ customComponents }{ customReadOnlyComps }
        </Grid>
    }
    return (<>{ demographicsComponents }{ contactComponents }{ insuranceComponents }{ clientComponents }{ providerComponents }{ customComponents }{ customReadOnlyComps }</>);
}

export default PatientEditor;