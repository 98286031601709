import * as React from 'react';
import { useCallback, useState } from 'react';
import { Client, UserAccessInfo } from '../models/core';
import { Practice } from '../models/ziphy';
import { Typography, Grid, IconButton, TextField, Checkbox } from '@mui/material';
import { GridFormItem } from './GridForm';

import EntityEditor, { EntityEditorProps } from './EntityEditor';
import { analytics } from '../utils/analytics/zipAnalytics';
import { useAuth } from '../hooks/useAuth';
import useDialog from '../hooks/useDialog'

import { AutocompleteEnumField } from './fields';

import ListItem from '@mui/material/ListItem';
import { ListItemSecondaryAction, ListItemButton, Tooltip, Box } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import SpinnerButton from './SpinnerButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BusyDialog from './BusyDialog';
import { API } from '../utils/Api';
import { API as ZiphyAPI } from '../utils/ZiphyAPI';
import { DateTime } from 'luxon';
import ShuttleList from './ShuttleList'
import SimpleDialog from './dialogs/SimpleDialog';
import useSnackbar from '../hooks/useSnackbar';
import AlertSnack from './ErrorSnack';

interface UserAccessItemProps {
    userData: UserAccessListItemData;
    isActive: boolean
    //onUpdate?:(user:UserAccessListItemData) => void;
    onUpdateStatus?:(user:UserAccessListItemData, active:boolean) => void;
}

interface UserAccessListItemData {
    name:string;
    id:number;
    options:string[],
    available:boolean;
}

const UserAccessListItem = ({ userData, isActive, onUpdateStatus, ...props }: UserAccessItemProps): JSX.Element => {

    /*const onUpdateUser = React.useCallback((label:string, values:string[]|null) => {
        userData.options = values||[];
        onUpdate && onUpdate(userData);
    }, [onUpdate, userData]);*/

    const onAddClick = React.useCallback(() => {
        userData.options = ['assignable'];
        onUpdateStatus && onUpdateStatus(userData, true);
    }, [onUpdateStatus, userData]);

    const onRemoveClick = React.useCallback(() => {
        onUpdateStatus && onUpdateStatus(userData, false);
    }, [onUpdateStatus, userData]);

    const activeDisplay = React.useMemo(() => {
        return (
        <ListItemSecondaryAction>
            <Tooltip id="tt-user-add" title='Remove the user from the clients active list'>
                <IconButton color='inherit' onClick={onRemoveClick}><RemoveIcon /></IconButton>
            </Tooltip>
        </ListItemSecondaryAction>)
    }, [onRemoveClick])

    const availableDisplay = React.useMemo(() => {
        return (<ListItemSecondaryAction>
            <Tooltip id="tt-user-add" title='Add user as an active user for client'>
                <IconButton color='inherit' onClick={onAddClick}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
        </ListItemSecondaryAction>)
    }, [onAddClick])

    return (
        <ListItem
            {...props}
            >
            <ListItemButton disableRipple>
                <ListItemText
                    primary={
                        <Typography component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                            {(userData.available ? '' : 'Not Found: ') + userData.name}
                        </Typography>
                    }
                    secondary={
                        <span>
                            User Id: {userData.id}
                        </span>}
                />
                { isActive ? activeDisplay : availableDisplay }
            </ListItemButton>
        </ListItem>
    );
};

interface AccessEditorProps extends Omit<EntityEditorProps,'title'|'doSave'> {
    client?: Client;
    practices?:Practice[]
}

const AccessEditor = ({ client, practices, readOnly, ...props }: AccessEditorProps): JSX.Element => {

    const [userFilterRoles, setUserFilterRoles] = useState<string[]>(['Admin', 'Practice Admin', 'Dispatcher']);

    const [practiceIdsList, setPracticeIdsList] = useState<string[]>([client?.practiceId ? client?.practiceId + '' : 'all']);
    const [syncingUsers, setSyncingUsers] = useState(false);
    const [api4, setApi4] = useState(false);

    const { open: busyOpen, show: showBusy, hide: hideBusy } = useDialog();
    const { open: addManual, show: showAddManual, hide: hideAddManaul } = useDialog();
    const { showError: snackError, ...snackProps } = useSnackbar();

    const [manaulUserId, setManualUserId] = useState(-1);
    

    const [sourceItems, setSourceItems] = useState<UserAccessListItemData[]>([]);
    const [targetItems, setTargetItems] = useState<UserAccessListItemData[]>(
        client?.userAccess?.map((u) => { return { name:u.name, id:u.id, options:u.options, available:true, } })
        || client?.repNames?.map((u) => { return { name:u, id:-1, options:['assignable'], available:true, } })
        || []
    );

    const renderListItem = (
        item: UserAccessListItemData,
        sourceItems: UserAccessListItemData[],
        setSourceItems: React.Dispatch<React.SetStateAction<UserAccessListItemData[]>>,
        targetItems: UserAccessListItemData[],
        setTargetItems: React.Dispatch<React.SetStateAction<UserAccessListItemData[]>>
      ) => (
        <UserAccessListItem key={`${item.id}-${item.name}`}
            userData={item} isActive={targetItems.includes(item)} 
            onUpdateStatus={
                (ud:UserAccessListItemData, active:boolean) => {
                    if(active) {
                        setSourceItems(sourceItems.filter((i) => i !== item));
                        setTargetItems([...targetItems, item]);
                    } else {
                        setTargetItems(targetItems.filter((i) => i !== item));
                        setSourceItems([...sourceItems, item]);
                    }
                }
        } ></UserAccessListItem>
    );


    const auth = useAuth();
    const isAdmin = React.useMemo(() => auth?.role == 'admin', [auth]);

    const availablePracticeIdList:string[] =React.useMemo(() =>{
        return[
            'all',
            ...(practices || []).flatMap((value)=> (value.id + '')) || []
        ]
    },[practices]);

    const outreachFilterMap: {[key: string]:string} = React.useMemo(() => {
        return {
            'Admin': 'admin',
            'Practice Admin': 'practice_admin',
            'Dispatcher': 'dispatcher',
            'Provider': 'provider',
            'Agent': 'agent',
        };
    }, []);

    
    const lastEditFooter = React.useMemo(() => {
        return { text:(!client?.userAccess || client?.userAccess.length == 0 
            ? 'WARNING: OLD REPLIST IN USE, PLEASE REGENERATE USER ACCESS LIST' : '') };
    }, [client]);

    const onSubmitClick = useCallback(async () => {
        
        showBusy();

        const users:UserAccessInfo[] = targetItems.map<UserAccessInfo>((item) => {
            return { 
                name:item.name,
                id:item.id,
                assignable:true,
                options:['assignable']
            }
        })
        const data = {
            userAccess:users,
            repNames:[...targetItems.map<string>((item)=>{return item.name}), 'Unknown']
        };
        if (client && users) {
            const ts = DateTime.now().toString();
            const assigner = auth?.user?.name || 'NA';
            analytics.track('user-access', { type:'assign', by: assigner, date:ts }, true);

            await API.updateClient({ ...client, ...data })
        }
        hideBusy();
        return true;
    }, [client, targetItems, showBusy, hideBusy, auth?.user]);

    const onUpdateUserList = useCallback(async () => {
        const ids = practiceIdsList.includes('all') ? Object.values(availablePracticeIdList).flatMap((b) => +b) : practiceIdsList.flatMap((c) => +c);
        setSyncingUsers(true);
        const filterRoles = userFilterRoles.flatMap((k) => outreachFilterMap[k]) || [];

        const response = await ZiphyAPI.getRoles(
                {
                    or: 
                    api4 ? [
                        { and: [ {contains: ['serviced_practice_ids', ids ]}, {eq: ['is_active', true]}, {in: ['role', filterRoles]},] },
                        { and: [ {in: ['practice_id', ids ]}, {eq: ['is_active', true]}, {in: ['role', filterRoles]},] } ]
                        : [{ and: [ {in: ['practice_id', ids ]}, {eq: ['is_active', true]}, {in: ['role', filterRoles]},] }]
                }
            ).catch(console.error);
        
        const roles = response?.roles;

        const newUserList:UserAccessListItemData[] = [];
        const knownIds:number[] = [...targetItems.map<number>((item)=> item.id)];

        //  Add in the the new users
        roles?.items?.forEach((item) => {
            if(item?.name != undefined && item?.user_id >= 0 && knownIds.indexOf(item?.user_id) == -1) {
                newUserList.push(
                    {
                        name:item?.name,
                        id:item?.user_id,
                        options:[],
                        available:true,
                    }
                );
                knownIds.push(item?.user_id);
            }
        });

        setSourceItems(newUserList);

        setSyncingUsers(false);
    }, [setSourceItems, setSyncingUsers, targetItems, practiceIdsList,
        userFilterRoles, outreachFilterMap, availablePracticeIdList, api4]);

    const onRoleSelectChange = React.useCallback((label: string, newValue: string[] | null) => {
        setUserFilterRoles(newValue || []);
    }, []);

    const onPracticeIdSelectChange = React.useCallback((label: string, newValue: string[] | null) => {
        setPracticeIdsList(newValue || []);
    }, []);

    const practiceIdSelectComps = React.useMemo(() => {
        const hasAccess = isAdmin
            || auth?.roles?.find((r) => { return r.role == 'admin' && (client?.practiceId &&(
                r.practice_id == client?.practiceId || r.serviced_practice_ids?.includes(client?.practiceId))) }) != undefined 
            || false;
        return hasAccess ? (<AutocompleteEnumField
            name='Practice Ids'
            value={practiceIdsList}
            options={availablePracticeIdList}
            hiddenLabel
            clearItem='all'
            onChange={onPracticeIdSelectChange}
        />)
        : (<TextField label={'Practice Id'} name={'pi' + client?.practiceId } 
            value={client?.practiceId || 'unknown'} variant='standard' disabled={true}/>);
    }, [auth?.roles, isAdmin, client, availablePracticeIdList, practiceIdsList, onPracticeIdSelectChange]);


    const checkValidUser = React.useCallback(async () => {
        showBusy();

        const response = await ZiphyAPI.getRoles(
            { eq: ['user_id', manaulUserId] }
        ).catch(console.error);
        console.log(response);
        if((response?.roles?.count || 0) == 0){
            snackError(new Error(`User ID: ${manaulUserId} not found. Check if valid`));
        }

        const expanded = response?.expanded;
        if((expanded?.users?.items?.length || 0) == 0) {
            snackError(new Error(`User ID: ${manaulUserId} not found. Check if valid`));
        }
        const user = expanded?.users?.items[0];
        setTargetItems([...targetItems, { name:user?.value.name||'', id:user?.value.id||-1, options:['assignable'], available:true }])

        hideBusy();
        hideAddManaul();
    }, [manaulUserId, showBusy, hideBusy, setTargetItems, snackError, targetItems, hideAddManaul]);


    const manaualAddUser = React.useMemo(() => {
        return (<SimpleDialog open={addManual}
                title={<Typography variant='h6' sx={{justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}> 
                    Add User
                </Typography>}
                body ={
                <Box component="section" sx={{ p: 2, border: '1px solid grey' }}>
                    <TextField label={'User ID'} name={'ao-userId' } value={manaulUserId} type='number' 
                        onChange={e=>setManualUserId(Number.parseInt(e.target.value))} variant='standard' disabled={!isAdmin}/>
                </Box>
            }
            buttons={
                [
                    {
                        text:'Close',
                        type:'button',
                        onClick:hideAddManaul
                    },
                    {
                        text:'Add',
                        color:'inherit',
                        onClick:checkValidUser
                    }
                ]
            }
            backdropClose={true}/>)
    }, [isAdmin,manaulUserId,addManual,hideAddManaul,checkValidUser]);


    React.useEffect(() => {
        setTargetItems(
            client?.userAccess?.map((u) => { return { name:u.name, id:u.id, options:u.options, available:true, } })
            || client?.repNames?.map((u) => { return { name:u, id:-1, options:['assignable'], available:true, } })
            || []
        );
        setSourceItems([]);
        setUserFilterRoles(['Admin', 'Practice Admin', 'Dispatcher']);
        setPracticeIdsList([client?.practiceId ? client?.practiceId + '' : 'all']);
        setSyncingUsers(false);
    }, [client])
    
    return (<EntityEditor {...props} readOnly={readOnly} title={(readOnly ? 'View' : 'Edit') + ' User Access'} 
                footer={lastEditFooter} doSave={onSubmitClick}
                dialogPaperProps={{sx:{maxWidth:'800px', height:'100%', minHeight:'90%'}}}>
            <GridFormItem xs={12}>
            <Grid container spacing={2}>
                <GridFormItem xs={12}>
                    <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                        { client?.name || client?.practiceId || 'unknown' }
                    </Typography>
                </GridFormItem>
                <GridFormItem xs={12}>
                    <Typography variant='h6' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Available User Filter</Typography>
                </GridFormItem>
                <GridFormItem xs={7}>
                    <AutocompleteEnumField
                        name='Role'
                        value={userFilterRoles}
                        options={Object.keys(outreachFilterMap)}
                        hiddenLabel
                        onChange={onRoleSelectChange}
                    />
                </GridFormItem>
                <GridFormItem xs={2}>
                    {practiceIdSelectComps}
                </GridFormItem>
                <GridFormItem xs={2}>
                    <Tooltip id="tt-user-au" title='Update the list of available users to add to active users. The current list of available users will be lost.'>
                        <SpinnerButton onClick={onUpdateUserList} showSpinner={syncingUsers} >Refresh</SpinnerButton>
                    </Tooltip>
                    </GridFormItem>
                <GridFormItem xs={1}>
                    <Tooltip id="tt-user-api" title='Use 4.0 API.'>
                    <Checkbox value={api4} onChange={() => setApi4(!api4)}/>
                    </Tooltip>
                </GridFormItem>

                <Grid container spacing={2}>
                <GridFormItem xs={12}>
                {manaualAddUser}
                </GridFormItem>
                </Grid>

                <GridFormItem xs={12}>
                <Box component="section" sx={{ p: 2, border: '1px solid grey' }}>
                <ShuttleList<UserAccessListItemData>
                    sourceTitle = 'Available Users'
                    targetTitle = 'Active Users'
                    sourceItems={sourceItems}
                    setSourceItems={setSourceItems}
                    targetItems={targetItems}
                    setTargetItems={setTargetItems}
                    itemsPerPage={25}
                    renderListItem={renderListItem}
                />
                </Box>
                </GridFormItem>

                <GridFormItem xs={11}/>
                <GridFormItem xs={1}>
                    <IconButton color='inherit' onClick={showAddManual}><AddIcon/></IconButton>
                </GridFormItem>
            </Grid>
            </GridFormItem>
            <AlertSnack {...snackProps}/>
            { busyOpen ? <BusyDialog open={busyOpen}/> : <></> }
            { addManual ? manaualAddUser : <></> }
        </EntityEditor >);
}

export default AccessEditor;