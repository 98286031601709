import axios from "axios";
import { DateTime } from "luxon";

import {
    Appointment, AuthData, Date, DTime, LoginParams, LoginResult, Office, Patient,
    Place, Practice, PracticeService, RefreshTokenParams, RequestCodeParams, RequestCodeResult,
    Role, Service, User
} from "../models/ziphy";


export interface Filter {
    and?: Filter[];
    or?: Filter[];
    eq?: [string, unknown];
    ne?: [string, unknown];
    gte?: [string, unknown];
    lte?: [string, unknown];
    imatch?: [string, unknown];
    in?: [string, unknown];
    contains?: [string, unknown];
    contained?: [string, unknown];
}

export interface List<T> {
    count: number;
    items: T[];
}

export interface Mapping<T, K = number> {
    items: { key: K; value: T }[];
}

interface ListPracticesResult {
    practices: List<Practice>;
}

export interface ListPracticeServicesResult {
    practice_services: List<PracticeService>;
    expanded: {
        services: Mapping<Service>;
    }
}

interface ListPatientsResult {
    patients: List<Patient>;
}

interface ListAppointmentsResult {
    appointments: List<Appointment>;
    expanded?: {
        patients: Mapping<Patient>;
        places: Mapping<Place>;
    }
}

interface ReadAppointmentResult {
    appointment: Appointment;
    expanded: {
        patients: Mapping<Patient>;
        places: Mapping<Place>;
    }
}

interface ListPlacesResult {
    places: List<Place>;
    expanded: {
        users: Mapping<User>;
    }
}

interface ListRolesResult {
    roles: List<Role>;
    expanded: {
        users: Mapping<User>;
        offices: Mapping<Office>;
        practices: Mapping<Practice>;
    }
}

interface ListOfficesResult {
    offices: List<Office>;
    // expanded?: {
    //     patients: Mapping<Patient>;
    //     places: Mapping<Place>;
    // }
}

interface SlotsRequest {
    begin: Date;
    service_id: number;
    place_id: number;
    patient_number: number;
    symptom_ids: number[];
    practice_id: number;
    office_id: number | null;
}

interface BookAppointmentRequest {
    practice_id: number;
    service_id: number;
    place_id: number;
    patients: { [k: string]: unknown }[];
    start: DTime;
    symptom_ids: number[];
    answers: { [k: number]: { [k: number]: number } };
    agent_role_id?: number;
    provider_role_id?: number;
    office_id?: number;
    optimistic?: boolean;
    description: string;
}

export interface SlotsResult {
    windows: {
        begin: DTime;
        end: DTime;
        items: Mapping<number[], DTime> | Record<string,never>;
    }
}

export interface ScheduleReportResponse {
    working_ranges: {
        begin: DTime;
        end: DTime;
        map: Mapping<{begin: DTime;end: DTime;}[]>;
    },
    role_map: Mapping<{name: string, role_ids: number[]}>;
}

interface Response<T> {
    result: T;
    error: {
        code: string,
        message: string,
        data: {
            reason: { [k: string]: string },
            method: string
        }
    } | null;
}

class _API {
    baseUrl: string;
    requestId: number;
    token: string;
    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
        this.requestId = 0;
        this.token = '';
    }

    requestCode = async (params: RequestCodeParams) => (
        await this.post<RequestCodeResult, RequestCodeParams>(
            'user.auth.request_code', 
            params,
            { access_token: undefined }
        )
    );

    login = async (params: LoginParams): Promise<AuthData> => {
        const result = await this.post<LoginResult, LoginParams>(
            'user.auth.login', 
            params,
            { access_token: undefined}
        );
        return {
            user: result.user,
            role: result.role,
            roles: result.roles?.items,
            account: result.account,
            accessToken: {
                value: result.access_token,
                expiresAt: DateTime.now().plus({ second: result.access_token_timeout }).toISO() || ''
            },
            sessionToken: {
                value: result.session_token,
                expiresAt: DateTime.now().plus({ second: result.session_token_timeout }).toISO() || ''
            },
        };
    };

    refreshToken = async (params: RefreshTokenParams): Promise<AuthData> => {
        const result = await this.post<LoginResult, RefreshTokenParams>(
            'user.auth.refresh', 
            params,
            { access_token: undefined}
        );
        return result && {
            user: result.user,
            role: result.role,
            roles: result.roles?.items,
            account: result.account,
            accessToken: {
                value: result.access_token,
                expiresAt: DateTime.now().plus({ second: result.access_token_timeout }).toISO() || ''
            },
            sessionToken: {
                value: result.session_token,
                expiresAt: DateTime.now().plus({ second: result.session_token_timeout }).toISO() || ''
            },
        };
    };

    getPractices = async () => (
        await this.post<ListPracticesResult, unknown>(
            'practices.list',
            { limit: 1000 }
        )).practices.items;

    getPracticeServices = async (practice_id: number) => (
        await this.post<ListPracticeServicesResult, unknown>(
            'practice_services.list',
            {
                filter: {
                    and: [
                        { eq: ["practice_id", practice_id] },
                        { eq: ["is_active", true] }
                    ]
                }, limit: 1000
            },
            { expand: { practice_service: ['service_id'] } }
        ));

    getPatients = async (filter: Filter) => (
        await this.post<ListPatientsResult, unknown>(
            'patients.list',
            { filter }
        ));

    createPatient = async (patient: Omit<Patient, 'id'>, practice_id: number) =>
        (await this.post<{patient: Patient}, (Omit<Patient, 'id'> & { practice_id: number })>(
            'patients.create',
            { ...patient, practice_id }
        )).patient

    getAppointment = async (id: string) => (
        await this.post<ReadAppointmentResult, unknown>(
            'appointments.read',
            { id: Number.parseInt(id, 10) },
            { expand: { appointment: ['patient_id', 'agent_id'] } }
        ));

    getAppointments = async (filter: Filter) => (
        await this.post<ListAppointmentsResult, unknown>(
            'appointments.list',
            { filter: filter, limit: 1000 },
            { expand: { appointment: ['patient_id', 'agent_id'] } }
        ));

    bookAppointment = async (request: BookAppointmentRequest) => (
        (await this.post<{appointment: Appointment}, BookAppointmentRequest>(
            'appointments.create',
            request
        )).appointment
    )


    getRoles = async (filter: Filter) => (
        await this.post<ListRolesResult, unknown>(
            'roles.list',
            { filter: filter, limit: 1000 },
            { expand: { role: ['user_id', 'office_id', 'practice_id'] } }
        )
    );

    getPlaces = async (filter: Filter) => (
        await this.post<ListPlacesResult, unknown>(
            'places.list',
            { filter: filter, limit: 1000 },
            { expand: { place: ['user_id'] } }
        )
    );

    createPlace = async (place: Omit<Place, 'id'>, practice_id: number) =>
        (await this.post<{place: Place}, (Omit<Place, 'id'> & { practice_id: number })>(
           'places.create',
            { ...place, practice_id }
        )).place

    getOffices = async (filter: Filter) => (
        await this.post<ListOfficesResult, unknown>(
            'offices.list',
            { filter: filter, limit: 1000 }
        )
    );

    getSlots = async (
        practice_id: number, place_id: number, service_id: number,
        office_id: number, begin: Date) => (
        await this.post<SlotsResult, SlotsRequest>(
            'schedules.find_windows',
            {
                practice_id, place_id, service_id, begin, office_id,
                patient_number: 1, symptom_ids: []
            },
            { expand: {} }
        )
    );

    getSchedulesReport = async (
        practice_id: number, office_id: number, begin: DTime, end: DTime
    ) => (
        await this.post<ScheduleReportResponse, unknown>(
            'schedules.report',
            {
                practice_id, begin, end,
                filter: {
                    or: [
                        { eq: ["office_id", office_id] },
                        { is_none: "office_id" }
                    ]
                },
            }
        )
    )

    async post<T, U = T>(path: string, data: U, meta?: { [k: string]: unknown }): Promise<T> {
        this.requestId += 1;
        const response = await axios.post<Response<T>>(
            this.baseUrl + '?' + path,
            {
                "id": this.requestId,
                "method": path,
                meta: { 
                    version: [3, 0], 
                    expand: { role: ['practice_id'] }, 
                    access_token: this.token,
                    ...meta,  
                },
                "params": data
            });
        if (typeof response.data === 'string') {
            return JSON.parse(response.data) as T;
        }
        if (response.data.result) {
            return response.data.result;
        }
        if (response.data.error) {
            console.error(response.data.error);
            throw Error('Ziphy API error: ' + JSON.stringify(response.data.error), {cause:response.data.error});
        }
        throw Error('Neither result, nor error', { cause: response.data })
    }

}

export const API = new _API(process.env.REACT_APP_ZIPHY_API_ENDPOINT || 'http://localhost:5000/myack-rpc/');
