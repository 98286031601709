import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import FiltersPanel, { FilterField, FilterValue } from '../FiltersPanel';
import { Client, PatientStatus, Plan, ScheduleJob } from '../../models/core';
import { Box } from '@mui/material';
import { API } from '../../utils/Api';
import { Message } from './MessagesBox';
import PatientsTable from './PatientsTable';
import { VisitStatuses } from '../../utils/enums';
import { useQuery } from '@tanstack/react-query';

function allIfEmpty(x: string[] | undefined): string[] {
    return (!x || x.length == 0) ? ['all'] : x;
}

function emptyIfAll(x: string[]): string[] {
    return x.includes('all') ? [] : x;
}

function planIdsToNames(planIds: string[] | undefined, plans: Plan[]): string[] | undefined {
    return planIds && planIds?.length > 0
        ? planIds.map(id => plans.find(plan => plan._id == id)?.name || 'unknown')
        : undefined;
}

function planNamesToIds(planNames: string[] | undefined, plans: Plan[]): string[] {
    return planNames && planNames.length > 0
        ? planNames.map(name => plans.find(plan => plan.name == name)?._id || '')
        : [];
}

type PatientsFilter = ScheduleJob['params']['patientsFilter'];

function patf2panel(filters: PatientsFilter, plans: Plan[]): FilterValue {
    return {
        city: filters?.city || [],
        planId: allIfEmpty(planIdsToNames(filters?.plan, plans)),
        'visit.status': allIfEmpty(filters?.status),
        'customFields.County': allIfEmpty(filters?.county),
        'customFields.RiskCategory': allIfEmpty(filters?.risk)
    };
}

function panel2patf(newValue: FilterValue, plans: Plan[]): PatientsFilter {
    return {
        city: newValue['city'] as string[],
        plan: planNamesToIds(emptyIfAll(newValue['planId'] as string[]), plans),
        status: emptyIfAll(newValue['visit.status'] as string[]),
        county: emptyIfAll(newValue['customFields.County'] as string[]),
        risk: emptyIfAll(newValue['customFields.RiskCategory'] as string[]),

    }
}
interface VisitsTabProps {
    client: Client;
    plans: Plan[];
    messages?: Message[];
    value: PatientsFilter;
    onChange?: (value: PatientsFilter) => void;
}

function PatientsTab({ client, plans, value, onChange }: VisitsTabProps): JSX.Element {

    const [filterValue, setFilterValue] = useState<FilterValue>(patf2panel(value, plans));

    React.useEffect(() => {
        setFilterValue(patf2panel(value, plans));
    }, [value, plans]);

    const onFiltersChange = useCallback((filterValue: FilterValue) => {
        onChange && onChange(panel2patf(filterValue, plans));
    }, [onChange, plans]);

    const filtersValid = useMemo(() => (
        filterValue['city'].length > 0
    ), [filterValue]);

    const query = useQuery({
        queryKey: ['patientsByFilter', client._id,
            value
        ] as [string, string, PatientsFilter],
        queryFn: async ({ queryKey: [_, clientId, filters] }) => {
            return await API.getPatients(
                clientId,
                Object.entries({
                    city: filters.city.join('|'),
                    planId: filters.plan.join('|'),
                    'status': PatientStatus.ACTIVE + '',
                    'visit.status': filters.status.join('|'),
                    'customFields.County': filters.county.join('|'),
                    'customFields.RiskCategory': filters.risk.join('|')
                }).filter(([_,v]) => v),
                1000, 0,
                [['lastName', 1], ['firstName', 1], ['dob', 1]]
            );
        },
        enabled: filtersValid
    });

    const citiesQuery = useQuery({
        queryKey: ['cities', client._id],
        queryFn: ({ queryKey: [_, clientId] }) => API.getPatientValues(clientId, 'city'),
        staleTime: 3600_000
    });
    const countiesQuery = useQuery({
        queryKey: ['counties', client._id],
        queryFn: ({ queryKey: [_, clientId] }) => API.getPatientValues(clientId, 'customFields.county'),
        staleTime: 3600_000
    });

    const fields = useMemo<FilterField[]>(() => [
        { name: 'city', type: 'autocomplete', items: citiesQuery.data?.filter(Boolean) || [], emptyLabel: 'Any City', width: 6 },
        { name: 'customFields.County', type: 'enum', emptyLabel: 'Any County', items: countiesQuery.data?.filter(Boolean) || [], width: 6 },
        { name: 'planId', type: 'enum', items: plans.map(p => p.name), emptyLabel: 'Any Plan', width: 4 },
        {
            name: 'customFields.RiskCategory', type: 'enum',
            items: ['Top 1%', 'Top 2-5%', 'Top 6-20%', 'Bottom 80%'],
            emptyLabel: 'Any Risk', width: 3
        },
        { name: 'visit.status', type: 'enum', items: VisitStatuses, emptyLabel: 'Any Status', width: 3 },
    ], [citiesQuery.data, countiesQuery.data, plans]);

    const refreshPatients = useCallback(async () => {
        if(!query.isFetched || query.isFetching) { return; }
        await query.refetch();
    }, [query]);

    return <Box sx={{ width: '720px', height: '512px', padding: '0 24px', display: 'flex', flexDirection: 'column' }}>
        <FiltersPanel padding={0} marginBottom='16px' height='100px' direction='row' fields={fields} defaultValue={filterValue} value={filterValue} onChange={onFiltersChange} />
        <PatientsTable
            patients={filtersValid && query.data?.items || []}
            loading={query.isFetching}
            client={client}
            refresh={refreshPatients}
            sx={{ height: '320px', width: '100%' }}
        />
    </Box>
}

export default PatientsTab;