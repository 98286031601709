import * as React from 'react';
import { Client, Plan, PatientStatus } from '../../models/core';
import FiltersPanel, { FilterField } from '../FiltersPanel';
import { EmailOutcomes, MailOutcomes, PatientStatuses, PhoneOutcomes, 
    RefusalReasons, ScheduledFailedReasons, TextOutcomes, VisitStatuses } from '../../utils/enums';

interface PatientFilterProps {
    client: Client;
    plans: Plan[];
    syncing:boolean;
    searchParams:Partial<Record<"page" | "sort" | FilterFields, string>>;
    userAssignmentFilters:string[];
    onChange:(searchFields:Partial<Record<any, string>>) => void;
}

export type FilterFields = 'search' | 'rgx.firstName' | 'rgx.lastName' | 'planId' 
    | 'visit.riskCategory' | 'rgx.county' | 'rgx.city' | 'zip'
    | 'visit.status'| 'visit.refusalReason'| 'key.phoneOutcome'| 'key.emailOutcome' 
    | 'key.textOutcome' | 'key.mailOutcome'
    | 'key.scheduledReason' | 'status' | 'key.outcomeMissing' | 'key.assignments';

const PatientFilterPanel = ({client, plans, syncing, searchParams, userAssignmentFilters, onChange}: PatientFilterProps): JSX.Element => {

    const filterValue = React.useMemo<{ [k in FilterFields]: string | string[] }>(() => ({
        search: searchParams.search || '',
        'rgx.firstName': searchParams['rgx.firstName'] || '',
        'rgx.lastName': searchParams['rgx.lastName'] || '',
        planId: searchParams.planId?.split('|') || '',
        'rgx.city': searchParams['rgx.city'] || '',
        zip: searchParams.zip || '',
        'rgx.county': searchParams['rgx.county'] || '',
        'visit.riskCategory': searchParams['visit.riskCategory']?.split('|') || ['all'],
        'visit.status': searchParams['visit.status']?.split('|') || ['all'],
        'visit.refusalReason': searchParams['visit.refusalReason']?.split('|') || ['all'],
        'key.phoneOutcome': searchParams['key.phoneOutcome']?.split('|') || ['all'],
        'key.emailOutcome': searchParams['key.emailOutcome']?.split('|') || ['all'],
        'key.textOutcome': searchParams['key.textOutcome']?.split('|') || ['all'],
        'key.mailOutcome': searchParams['key.mailOutcome']?.split('|') || ['all'],
        'key.scheduledReason': searchParams['key.scheduledReason']?.split('|') || ['all'],
        'status' : !searchParams['status'] ? [PatientStatus.ACTIVE] : (searchParams['status']?.split('|') || ['all']),
        'key.outcomeMissing': searchParams['key.outcomeMissing']?.split('|') || ['all'],
        'key.assignments': searchParams['key.assignments']?.split('|') || ['all']
    }), [searchParams])


    const onFiltersChange = React.useCallback((value: { [k: string]: string | string[] }) => {
        function maybeJoin(x: string | string[]): string {
            return x == 'all' ? '' : (x instanceof Array) ? (x.includes('all') ? 'all' : x.join('|')) : x;
        }
        onChange({
            search: maybeJoin(value.search) || '',
            'rgx.firstName': maybeJoin(value['rgx.firstName']) || '',
            'rgx.lastName': maybeJoin(value['rgx.lastName']) || '',
            planId: plans.filter(p => (value.planId as string[]).includes(p.name)).map(p => p._id).join('|'),
            'rgx.city': maybeJoin(value['rgx.city']) || '',
            zip: maybeJoin(value.zip) || '',
            'visit.riskCategory': maybeJoin(value['visit.riskCategory']) || '',
            'rgx.county': maybeJoin(value['rgx.county']) || '',
            'visit.status': maybeJoin(value['visit.status']) || '',
            'visit.refusalReason': maybeJoin(value['visit.refusalReason']) || '',
            'key.phoneOutcome': maybeJoin(value['key.phoneOutcome']) || '',
            'key.emailOutcome': maybeJoin(value['key.emailOutcome']) || '',
            'key.textOutcome': maybeJoin(value['key.textOutcome']) || '',
            'key.mailOutcome': maybeJoin(value['key.mailOutcome']) || '',
            'key.scheduledReason': maybeJoin(value['key.scheduledReason']) || '',
            'status': maybeJoin(value['status']) || '',
            'key.outcomeMissing': maybeJoin(value['key.outcomeMissing']) || '',
            'key.assignments': maybeJoin(value['key.assignments']) || '',
            page:'1'
        });
    }, [plans, onChange]);

    const filterFields = React.useMemo<FilterField[]>(() => [
        { name: 'search', type: 'search', placeholder: 'Insurance/Medicare/Medicaid ID', width: 12},
        { name: 'rgx.firstName', type: 'string', placeholder: 'First Name', width: 12},
        { name: 'rgx.lastName', type: 'string', placeholder: 'Last Name', width: 12},
        { name: 'rgx.city', type: 'string', placeholder: 'City', width: 12},
        {
            name: 'rgx.county', type: 'string', placeholder: 'County',
            disabled: !client.layout.patientFields.find(f => f.field == 'county'), width: 12,
        },
        {name: 'zip', type: 'string', placeholder: 'ZIP', width: 12},
        {name: 'planId', type: 'enum', items: plans.map(p => p.name), emptyLabel: 'All Plans', width: 12},
        {name: 'visit.riskCategory', type: 'enum', items: ['Top 1%', 'Top 2-5%', 'Top 6-20%', 'Bottom 80%'], emptyLabel: 'Any Risk', 
            disabled: !client.layout.patientFields.find(f => f.field && f.field=='riskCategory'), width: 12},
        {name: 'visit.status', type: 'enum', items: VisitStatuses, emptyLabel: 'Any Status', width: 12},
        {name: 'visit.refusalReason', type: 'enum', items: RefusalReasons, emptyLabel: 'Any Refusal Reason', width: 12},
        {name: 'key.phoneOutcome', type: 'enum', items: PhoneOutcomes, emptyLabel: 'Any Phone Outcome', width: 12},
        {name: 'key.emailOutcome', type: 'enum', items: EmailOutcomes, emptyLabel: 'Any Email Outcome', width: 12},
        {name: 'key.textOutcome', type: 'enum', items: TextOutcomes, emptyLabel: 'Any Text Outcome', width: 12},
        {name: 'key.mailOutcome', type: 'enum', items: MailOutcomes, emptyLabel: 'Any Mail Outcome', width: 12},
        {name: 'key.scheduledReason', type: 'enum', items: ScheduledFailedReasons, emptyLabel: 'Any Schedule Reason', width: 12},
        {name: 'status', type: 'enum', items: PatientStatuses, emptyLabel: "Any Status", width: 12},
        {name: 'key.outcomeMissing', type: 'enum', forceSingle:true, items: [
            'No Phone 1st attempt', 'No Phone 2nd attempt', 'No Phone 3rd attempt',
            'No Phone 4th attempt', 'No Phone 5th attempt', 'No Phone 6th attempt',
            'No Email attempts', 'No Text attempts', 'No Mail attempts'
        ], emptyLabel: "Any Attempts", width: 12},
        {name: 'key.assignments', type: 'enum', items: userAssignmentFilters ? [
            'Assigned',
            ...userAssignmentFilters.filter((f) => f !== 'None'),
            'None'
            ] : [], emptyLabel:'Any Assignment', 
            disabled:!(client.repNames?.length != 0), width: 12},
    ], [client.layout.patientFields,client.repNames, plans, userAssignmentFilters]);


    return (<FiltersPanel fields={filterFields} defaultValue={filterValue}
        isBusy={syncing} onChange={onFiltersChange} collapsable={true} gridPadding='2px 0px 8px 8px'/>)
}
export default PatientFilterPanel;