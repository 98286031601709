import * as React from 'react';
import { useCallback, useState } from 'react';
import { DocumentInfo } from '../models/core';
import { Typography, Grid, Box, IconButton } from '@mui/material';
import { authDocumentInfo } from '../utils/ResourceUtils';
import { GridFormItem } from './GridForm';
import { useAuth } from '../hooks/useAuth';
import useSnackbar from '../hooks/useSnackbar';
import AlertSnack from './ErrorSnack'

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import TextTable from './TextTable';

export enum PatientConditionPanelSection {
    Demographics = 'Demographics',
    Suspect = 'Suspect',
    Chronic = 'Chronic',
    Diagnosis = 'Diagnosis'
}

export interface PatientConditionPanelData {
    _id:string;
    visitId?:string;
    name:string;
    value:string;
    info:DocumentInfo;
}

export interface PatientConditionEditorState {
    updated:boolean;
    suspect:PatientConditionPanelData[];
    chronic:PatientConditionPanelData[];
    diagnosis:PatientConditionPanelData[];
}

interface ConditionInfoProps {
    readonly?:boolean;
    sections?:PatientConditionPanelSection[];
    state?:PatientConditionEditorState;
    onStateUpdate?:(state:PatientConditionEditorState) => void;
}

const ConditionInfoEditor = ({ sections, state, onStateUpdate, readonly=false }: ConditionInfoProps): JSX.Element => {

    const { showError: snackError, ...snackProps } = useSnackbar();
    const [suspectConditionList, setSuspectConditionList] = useState<PatientConditionPanelData[]>(state?.suspect || []);
    const [chronicConditionList, setChronicConditionList] = useState<PatientConditionPanelData[]>(state?.chronic || []);
    const [diagnosisList, setDiagnosisList] = useState<PatientConditionPanelData[]>(state?.diagnosis || []);
    const [changesMade, setChangesMade] = useState<boolean>(false);

    const auth = useAuth();

    React.useEffect(() => {
        if(!changesMade) {
            return;
        }
        onStateUpdate && onStateUpdate(
            {
                updated:true,
                chronic:chronicConditionList,
                suspect:suspectConditionList,
                diagnosis:diagnosisList
            }
        );
    }, [suspectConditionList, chronicConditionList, diagnosisList, onStateUpdate, changesMade]);

    const checkRenderSection = useCallback((section:PatientConditionPanelSection, 
        array:PatientConditionPanelSection[]|undefined, def = true) => {
        return (!array || array?.length == 0) ? def : array?.findIndex((s) => section == s) >= 0;
    },[]);

    //  Demographics
    const demographicsComponents = React.useMemo<JSX.Element>(() => {
        return checkRenderSection(PatientConditionPanelSection.Demographics, sections, true) ? (<GridFormItem xs={12}>
            <Grid container spacing={2}>
                <GridFormItem xs={12}>
                    <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Conditions</Typography>
                </GridFormItem>
                {/*<GridFormItem xs={2}>
                    <EnumField name='listFilter' label='Show' value={'All'} 
                        onChange={(e) => {console.log(e)}} options={['All', 'Patient']} required readOnly={readOnly} />
                </GridFormItem>*/}
            </Grid>
            <GridFormItem xs={12}><Box sx={{ height: '0.5em' }} /></GridFormItem>
            </GridFormItem>) : <></>;
    },[sections, checkRenderSection]);


    const copyTableContent = React.useCallback((contentName:string, content: PatientConditionPanelData[]) => {
        let copyData:string = contentName;
        content?.forEach((it) => { copyData = copyData + `\n${it.name}: ${it.value}`; });
        navigator.clipboard.writeText(copyData);
    },[])

    const checkCanAddRow = React.useCallback((nr: PatientConditionPanelData, cbk:()=>void) => {
        if(!nr.name || nr.name == '') {
            snackError(new Error('Row name must be set'));
            return [{ key:'name', label:'Row name must be set' }];
        }
        cbk();
        return undefined;
    }, [snackError]);


    const createEditConditionComp = React.useCallback((
        setList:(value:React.SetStateAction<PatientConditionPanelData[]>) => void,
        list:PatientConditionPanelData[],
        title:string,
        addRowHeader:string,
        section:PatientConditionPanelSection
    ) => {
        const handleAddRow = (newRow: PatientConditionPanelData) => {
            setList([...list, {...newRow, _id:Date.now().toString(), info:authDocumentInfo(auth?.user, `create ${section}`)}]);
            setChangesMade(true);
        };

        const handleDeleteRow = (newRow: PatientConditionPanelData) => {
            setList([...list.filter(el => el._id != newRow._id)]);
            setChangesMade(true);
        };

        const handleEditRow = (newRow: PatientConditionPanelData) => {
            const index = list.findIndex(el => el._id == newRow._id);
            if(index !== -1) {
                setList(prevArray => {
                    const newArray = [...prevArray];
                    newArray[index] = { ...newRow, info:authDocumentInfo(auth?.user, `edit ${section}`)};
                    return newArray;
                });
                setChangesMade(true);
            }
        };

        return checkRenderSection(section, sections, true) ? (
            <Grid container spacing={2}>
            <GridFormItem xs={11}>
                <Typography variant='h6' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>{title}</Typography>
            </GridFormItem>
            <GridFormItem xs={1}>
                <IconButton onClick={() => copyTableContent(title, list)}><ContentCopyIcon/></IconButton>
            </GridFormItem>
            <GridFormItem xs={12}>
                <TextTable
                    readonly={readonly}
                    addRowHeader={addRowHeader}
                    rows={list}
                    columns={[
                        { key: 'name', label: 'Name' },
                        { key: 'value', label: 'ICD' }
                    ]}
                    onAddRow={handleAddRow}
                    canAddRow={checkCanAddRow}
                    onDeleteRow={handleDeleteRow}
                    onUpdateRow={handleEditRow}
                />
            </GridFormItem>
            <GridFormItem xs={12}><Box sx={{ height: '0.5em' }} /></GridFormItem>
            </Grid>
            ) : <></>;
    },[checkCanAddRow,copyTableContent,sections,checkRenderSection,auth?.user,setChangesMade,readonly]);


    const suspectConditionComponents = React.useMemo<JSX.Element>(() => {
        return createEditConditionComp(setSuspectConditionList, suspectConditionList, 'Suspect Conditions',
            "Enter the suspect condition and ICD code:",PatientConditionPanelSection.Suspect
        );
    },[suspectConditionList, setSuspectConditionList, createEditConditionComp]);

    const chronicConditionComponents = React.useMemo<JSX.Element>(() => {
        return createEditConditionComp(setChronicConditionList, chronicConditionList, 'Chronic Conditions',
            "Enter the chronic condition and ICD code:",PatientConditionPanelSection.Chronic
        );
    },[chronicConditionList, setChronicConditionList, createEditConditionComp]);

    const diagnosisComponents = React.useMemo<JSX.Element>(() => {
        return createEditConditionComp(setDiagnosisList, diagnosisList, 'Diagnosis',
            "Enter the diagnosis and ICD code:",PatientConditionPanelSection.Diagnosis
        );
    },[diagnosisList, setDiagnosisList, createEditConditionComp]);
   
    return <Grid container spacing={2}>
        <GridFormItem xs={12}>
                { demographicsComponents } { suspectConditionComponents }{ chronicConditionComponents }{ diagnosisComponents }
        </GridFormItem>
        <AlertSnack {...snackProps}/>
    </Grid>
}

export default ConditionInfoEditor;